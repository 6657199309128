<script>
import {defineComponent} from 'vue'
import ContainerInput from "./components/ContainerInput.vue"
import AgreedRate from "./components/AgreedRateInput.vue"
import AdditionalCost from "./components/AdditionalCostInput.vue"
import ActualCost from "./components/ActualCostInput.vue"
import Counterparty from "./components/Counterparty.vue"
import DeleteContainer from "./components/DeleteContainer.vue";
import AddRow from "@/views/pages/orders/components/AddRow.vue";
import store from "@/state/store";

export default defineComponent({
  name: "ActualCostTab",
  emits: ['update'],
  props: {
    order_counterparties: {
      type: Array,
      required: true,
      default: () => []
    },
    order_container_types: {
      type: Array,
      required: true,
      default: () => []
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => []
    },
    category_list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  components: {
    AddRow,
    ContainerInput,
    AgreedRate,
    AdditionalCost,
    ActualCost,
    Counterparty,
    DeleteContainer
  },
  data() {
    return {
      copied_containers: '',
      blacklisted_users_ids: []
    }
  },
  computed: {
    counterparties() {
      return this.order_counterparties
    },
    container_types() {
      return this.order_container_types
    },
    agreed_rate_sum() {
      let sum = 0
      this.container_types.forEach(container_type => {
        container_type.expanses.forEach(expense => {
          sum += parseFloat(expense.agreed_rate)
        })
      })
      return sum
    },
    additional_cost_sum() {
      let sum = 0
      this.container_types.forEach(container_type => {
        container_type.expanses.forEach((expense) => {
          sum += parseFloat(expense.additional_cost)
        })
      })
      return sum
    },
    sum_total_expenses() {
      return (this.counterparties.reduce((total, counterparty) =>
          total + parseFloat(this.sumCounterpartyExpenses(counterparty)), 0));
    },
    sum_profit() {
      return ((parseFloat(this.agreed_rate_sum) + parseFloat(this.additional_cost_sum)) - parseFloat(this.sum_total_expenses))
    },
    user() {
      return store.state.user
    }
  },
  methods: {
    updateCounterparty(counterparty) {
      console.log(counterparty)
    },
    sumCounterpartyExpenses(counterparty) {
      return (this.container_types.flatMap(container_type => container_type.expanses)
          .flatMap(expense => expense.actual_costs)
          .filter(actual_cost => actual_cost.counterparty_id === counterparty.id)
          .reduce((total, actual_cost) => total + parseFloat(actual_cost.actual_cost), 0))
    },
    calculateContainerTotal(container) {
      if (!container.actual_costs) return 0
      return container.actual_costs
          .map(c => c.actual_cost ? c.actual_cost : 0)
          .reduce((a, actual_cost) => {
            return a + parseFloat(actual_cost);
          }, 0)
          .toFixed(1)
    },
    calculateContainerProfit(container) {
      if (container) {
        return ((parseFloat(container.agreed_rate) + parseFloat(container.additional_cost)) -
            this.calculateContainerTotal(container)
        ).toFixed(1)
      }
    },
    async copyContainers() {
      let containers = this.container_types
          .flatMap(container_type => container_type.expanses)
          .filter(expense => expense.container)
          .map(expense => expense.container.name)
      if (containers.length === 0) return

      document.getElementById('copied_containers_content').innerText = containers.join('\n')
      document.getElementById('CopyContainersModalBtn').click()
    }
  },
})
</script>

<template>

  <button id="CopyContainersModalBtn"
          data-bs-toggle="modal"
          data-bs-target="#CopyContainersModal"
          class="visually-hidden"></button>
  <div id="CopyContainersModal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Copy Containers
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="fs-4" id="copied_containers_content">
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-pane active" id="actual_cost_tab" role="tabpanel">
    <div class="table-responsive table-card">
      <table class="table table-striped mb-0">
        <thead>
        <tr class="bg-light align-middle">
          <th class="text-center">#</th>
          <th class="text-center">Container</th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed rate</th>
          <th class="text-center">Additional Cost</th>
          <th class="text-center py-0 m-0" v-for="counterparty in counterparties"
              :key="`counterparty + ${counterparty.id}`"
              @click="updateCounterparty(counterparty)">
            <Counterparty
                @update="this.$emit('update')"
                :counterparty="counterparty"
                :counterparty_list="counterparty_list"
                :category_list="category_list"
            />

          </th>
          <th class="text-center">Total</th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Profit</th>
        </tr>
        <tr class="bg-light align-middle fs-5">
          <th class="text-center">
          </th>
          <th class="text-center">
            <i @click="copyContainers()" class="mdi mdi-content-copy c_icon_hoverable"></i>
          </th>
          <th v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">$ {{
              agreed_rate_sum.toLocaleString(undefined, {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })
            }}</th>
          <th class="text-center">
            ${{
              additional_cost_sum.toLocaleString(undefined, {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })
            }}
          </th>
          <th class="text-center m-0" v-for="party in counterparties"
              :key="party.id">
            ${{
              sumCounterpartyExpenses(party).toLocaleString(undefined, {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })
            }}
          </th>
          <th class="text-center">${{
              sum_total_expenses.toLocaleString(undefined, {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })
            }}</th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">${{
              sum_profit.toLocaleString(undefined, {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })
            }}</th>
        </tr>
        </thead>
        <tbody v-for="ctr_type in container_types" :key="ctr_type">
        <tr class="border-success">
          <th class="my-1 bg-transparent text-success"
              :colspan="blacklisted_users_ids.includes(user.id) ? 5 : 6 + counterparties.length">
            <div class="d-flex justify-content-between">
              {{ ctr_type.container_type }}
              <AddRow @update="this.$emit('update')"
                      :container_type_id="ctr_type.id"
                      :container_type="ctr_type.container_type"
              />
            </div>
          </th>
        </tr>
        <tr class="align-middle text-center"
            v-for="(container, i) in ctr_type.expanses" :key="container"
            @mouseover="container.is_hovered = true"
            @mouseleave="container.is_hovered = false"
        >
          <th>
            <DeleteContainer
                :index="i"
                :container="container"
                @update="this.$emit('update')"
            />
          </th>
          <td class="text-center" style="max-width: 125px">
            <ContainerInput
                @update="this.$emit('update')"
                :expense_id="container.id"
                :container_type_id="ctr_type.id"
                :container="container.container"
                :in_terminal="container.actual_costs.map(i => i.in_terminal).includes(true)"
                :acts_list="container.actual_costs.map(a => a.act).filter(i => i).map(i => i ? i.name : i)"
            />
          </td>
          <td v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">
            <AgreedRate
                @update="this.$emit('update')"
                :agreed_rate="container.agreed_rate"
                :expense_id="container.id"
                :container_type_id="ctr_type.id"
            />
          </td>
          <td class="text-center">
            <AdditionalCost
                @update="this.$emit('update')"
                :expense_id="container.id"
                :container_type_id="ctr_type.id"
                :additional_cost="container.additional_cost"/>
          </td>
          <td class="text-center"
              v-for="counterparty in container.actual_costs"
              :key="counterparty.id" style="max-width: 65px">
            <ActualCost
                @update="this.$emit('update')"
                :code="counterparty.code"
                :actual_cost="counterparty.actual_cost"
                :counterparty_id="counterparty.id"
                :act="counterparty.act"
            />
          </td>
          <td>
            ${{ calculateContainerTotal(container) }}
          </td>
          <td v-if="!blacklisted_users_ids.includes(user.id)">
            ${{ calculateContainerProfit(container) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

</style>